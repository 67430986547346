import { axiosInstance, scbAxiosInstance } from "../services";
import { loaderStore } from "../stores";

export const fetchOpenOrders = async (params = {}) => {
    const resp = await scbAxiosInstance.get("/reseller/orders.json", {params})
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const fetchHybridOrders = async (params = {}) => {
    const loaderState = loaderStore();
    const resp = await axiosInstance.get("/api/orders/my_orders", {params})
    .then(res => {
        loaderState.setLoading(false, []);
        return res;
    }).catch((e) => {
        loaderState.setLoading(false, []);
        return e.response
    })
    return resp;
}